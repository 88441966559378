<template>
  <v-container
    fluid
    v-scroll="loadMore"
  >
    <div>
      <v-row>
        <v-col>
          <v-toolbar
            class="align-items-center"
            color="transparent"
            flat
          >
            <v-card-title
              class="d-none d-sm-block pl-0"
            >
              {{ $t('locations.list.title') }}
            </v-card-title>

            <v-spacer />

            <v-text-field
              v-model="query.textSearch"
              append-inner-icon="mdi-magnify"
              :label="`Søg blandt lokationer`"
              placeholder="Skriv for at søge"
              :disabled="loading"
              autofocus
              hide-details
              solo
              @keyup.enter="onUserSearch"
            />

            <ListSearchButton
              class="ml-3"
              :loading="loading || searching"
              @click="onUserSearch"
            />
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row
        align="start"
      >
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <ListFilters
            :loading="loading || searching"
            @click:reset="resetFilters"
            @click:search="onUserSearch"
          >
            <v-autocomplete
              v-model="query.countryIds"
              :items="metadata.countries"
              item-text="name"
              item-value="id"
              :label="$t('search.countries')"
              :search-input.sync="countryInput"
              auto-select-first
              small-chips
              multiple
              outlined
              dense
            />
            <v-checkbox
              v-model="query.includeSupport"
              :label="$t('locations.list.includeSupport')"
              append-icon="mdi-headset"
              hide-details
            />
            <v-checkbox
              v-model="query.includeTaskRequests"
              :label="$t('locations.list.includeTaskRequests')"
              append-icon="mdi-format-list-checks"
              hide-details
            />
            <v-checkbox
              v-model="query.includeDeliveries"
              :label="$t('locations.list.includeDeliveries')"
              append-icon="mdi-hammer-screwdriver"
              hide-details
            />
            <v-checkbox
              v-model="query.includeHistory"
              :label="$t('search.includeHistory')"
              hide-details
            />
          </ListFilters>
        </v-col>

        <v-col>
          <div>
            <v-progress-linear
              :active="searching"
              indeterminate
            />

            <template
              v-if="!locations.length && loading"
            >
              <v-row
                v-for="n in 3"
                :key="n"
              >
                <v-col>
                  <CaseCardSkeletonLoader />
                </v-col>
              </v-row>
            </template>

            <template v-for="location in locations">
              <v-row :key="location.id + '-title'">
                <v-col class="mt-4">
                  <h2>{{ location.title }} ({{ location.countryName }})</h2>
                </v-col>
              </v-row>
              <v-row :key="location.id">
                <v-col>
                  <DeliveryCard
                    v-for="delivery in getForLocation(location.id, deliveries)"
                    :key="delivery.id"
                    :item="delivery"
                    :metadata="getMetadata('delivery')"
                    :to="`delivery/${delivery.id}`"
                    hide-status
                    class="mb-6"
                  />

                  <SupportTicketCard
                    v-for="ticket in getForLocation(location.id, supportTickets)"
                    :key="ticket.id"
                    :item="ticket"
                    :metadata="getMetadata('supportTicket')"
                    :to="`supportticket/${ticket.id}`"
                    hide-status
                    class="mb-6"
                  />

                  <TaskRequestCard
                    v-for="taskRequest in getForLocation(location.id, taskRequests)"
                    :key="taskRequest.id"
                    :item="taskRequest"
                    :metadata="getMetadata('taskRequest')"
                    :to="`taskrequest/${taskRequest.id}`"
                    hide-status
                    class="mb-6"
                  />

                  <v-card v-if="isLocationEmpty(location)" flat>
                    <v-card-text>
                      Ingen igangværende sager på lokationen
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <v-row v-if="!locations.length && !loading">
              <v-col>
                <v-card
                  flat
                  disabled
                >
                  <v-card-text class="d-flex justify-center py-10">
                    Ingen resultater
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row
              justify="center"
            >
              <v-fade-transition>
                <v-card
                  v-if="searching || noMoreItems"
                >
                  <v-card-text>
                    <template v-if="noMoreItems">
                      <div>
                        Ikke flere resultater
                      </div>
                    </template>
                    <template v-else>
                      <div>Indlæser flere</div>
                      <v-progress-linear indeterminate />
                    </template>
                  </v-card-text>
                </v-card>
              </v-fade-transition>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import DeliveryCard from '@/components/delivery/DeliveryCard';
import SupportTicketCard from '@/components/support-ticket/SupportTicketCard';
import TaskRequestCard from '@/components/task-request/TaskRequestCard';
import Loading from '@/components/Loading';
import ListFilters from '@/components/ListFilters.vue';
import ListSearchButton from '@/components/ListSearchButton.vue';
import CaseCardSkeletonLoader from '@/components/CaseCardSkeletonLoader.vue';

export default {
  name: 'Locations',
  components: {
    DeliveryCard,
    SupportTicketCard,
    TaskRequestCard,
    Loading,
    ListFilters,
    ListSearchButton,
    CaseCardSkeletonLoader
  },
  data() {
    return {
      metadata: {},
      query: {},
      locations: [],
      deliveries: Array,
      supportTickets: Array,
      taskRequests: Array,
      loading: false,
      initialQuery: {
        includeDeliveries: true,
        includeSupport: true,
        includeTaskRequests: true,
        offset: 0,
        take: 5,
        readonly: true,
        userId: this.$store.getters.userId
      },
      noMoreItems: false,
      countryInput: null,
      searching: false,
    };
  },
  computed: {
    ...mapGetters(['showOnlyRelevantContent']),
    countries: function () {
      return [...new Set(this.metadata.locations?.map((x) => ({ id: x.countryId, name: x.countryName })))] ?? [];
    }
  },
  watch: {
    showOnlyRelevantContent() {
      this.onUserSearch();
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;

      // Set initial query
      this.query = JSON.parse(JSON.stringify(this.initialQuery));

      try {
        const { locations, deliveries, supportTickets, taskRequests } = await this.doSearch(this.$AuthService, this.query);

        this.locations = locations;
        this.deliveries = deliveries;
        this.supportTickets = supportTickets;
        this.taskRequests = taskRequests;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getForLocation(locationId, items) {
      return items.filter((x) => x.locationId === locationId);
    },
    getMetadata(type) {
      const {
        departments,
        subjectAreas,
        deliveryStatuses,
        supportTicketStatuses,
        types,
        categories
      } = this.metadata;

      switch (type) {
        case 'delivery':
          return {
            departments,
            subjectAreas,
            statuses: deliveryStatuses,
            types
          };

        case 'supportTicket':
          return {
            departments,
            subjectAreas,
            statuses: supportTicketStatuses,
            categories
          };

        case 'taskRequest':
          return {
            departments,
            subjectAreas
          };

        default:
          return {
            departments,
            subjectAreas
          };
      }
    },

    sortByTitle(items) {
      if (items?.length > 0) {
        return [...items].sort((a, b) => a.title.localeCompare(b.title));
      }

      return [];
    },

    /**
     * Search for locations, given the current query set in the model.
     */
    async search() {
      this.searching = true;

      try {
        const { model, locations, deliveries, supportTickets, taskRequests } = await this.doSearch(this.$AuthService, this.query);

        if (!this.query.offset) {
          this.deliveries = deliveries;
          this.supportTickets = supportTickets;
          this.taskRequests = taskRequests;
        }

        return { model, locations, deliveries, supportTickets, taskRequests };
      } catch (error) {
        console.error(error);
      } finally {
        this.searching = false;
      }
    },

    async doSearch(authService, query) {
      this.metadata = await this.$AuthService.api.get('location/metadata', { params: query }).then(res => res.data);
      const locationsResponse = await authService.api.post('location/ListSearch', query);
      const model = locationsResponse.data;

      let deliveryIds = [];
      let supportTicketIds = [];
      let taskRequestIds = [];

      model.data.locations.forEach((loc) => {
        loc.deliveries.forEach(item => deliveryIds.push(item.value));
        loc.supportTickets.forEach(item => supportTicketIds.push(item.value));
        loc.taskRequests.forEach(item => taskRequestIds.push(item.value));
      });

      const deliveryPromise = deliveryIds.length
        ? authService.api.post('delivery/ListSearch', {
            ids: deliveryIds,
            includeActive: true,
            includeClosed: query.includeHistory
          })
        : Promise.resolve([]);

      const supportTicketPromise = supportTicketIds.length
        ? authService.api.post('supportticket/ListSearch', {
            ids: supportTicketIds,
            includeClosed: query.includeHistory
          })
        : Promise.resolve();

      const taskRequestPromise = taskRequestIds.length
        ? authService.api.post('TaskRequest/ListSearch', {
            ids: taskRequestIds,
            includeHistory: query.includeHistory
          })
        : Promise.resolve();

      const promises = [ deliveryPromise, supportTicketPromise, taskRequestPromise ];

      const [deliveriesResponse, supportTicketsResponse, taskRequestsResponse] = await Promise.all(promises);

      return {
        model,
        locations: locationsResponse.data.data.locations,
        deliveries: this.sortByTitle(deliveriesResponse?.data?.data?.deliveries),
        supportTickets: this.sortByTitle(supportTicketsResponse?.data?.data?.supportTickets),
        taskRequests: this.sortByTitle(taskRequestsResponse?.data?.data?.taskRequests)
      };
    },

    async onUserSearch() {
      this.query.offset = 0;
      this.noMoreItems = false;
      this.locations = [];
      this.deliveries = [];
      this.supportTickets = [];
      this.taskRequests = [];

      this.$vuetify.goTo(0);

      const { locations, deliveries, supportTickets, taskRequests } = await this.search();

      this.locations = locations;
      this.deliveries = deliveries;
      this.supportTickets = supportTickets;
      this.taskRequests = taskRequests;
    },

    async loadMore() {
      if (this.noMoreItems) {
        return;
      }

      const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 50;

      if (bottomOfWindow && !this.searching) {
        this.query.offset += this.query.take;
  
        const { locations, deliveries, supportTickets, taskRequests } = await this.search();

        if (!locations.length) {
          this.noMoreItems = true;
        } else {
          this.locations = this.locations ? this.locations.concat(locations) : locations;
          this.deliveries = this.deliveries ? this.deliveries.concat(deliveries) : deliveries;
          this.supportTickets = this.supportTickets ? this.supportTickets.concat(supportTickets) : supportTickets;
          this.taskRequests = this.taskRequests ? this.taskRequests.concat(taskRequests) : taskRequests;
        }
      }
    },

    resetFilters() {
      this.query = JSON.parse(JSON.stringify(this.initialQuery));
    },

    isLocationEmpty(location) {
      return !location.deliveries.length & !location.supportTickets.length & !location.taskRequests.length
    }
  }
};
</script>